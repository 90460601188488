%word-nobreak
  display: block
  overflow: hidden
  text-overflow: ellipsis
  white-space: nowrap
%text-shadow
  text-shadow: 0px 1px 8px rgba(#000, 0.4)
%box-shadow
  box-shadow: 0px 1px 15px rgba(#000, 0.3)
%noto-sans-tc
  font-family: 'Noto Sans TC', sans-serif
%Raleway
  font-family: 'Raleway', 'Noto Sans TC', sans-serif
%Oswald
  font-family: 'Oswald', 'Noto Sans TC', sans-serif
%card-over-link
  width: 100%
  height: 100%
  width: 100%
  height: 100%
  position: absolute
  top: 0
  left: 0
  z-index: 6
%word-break-1
  overflow: hidden
  white-space: nowrap
  text-overflow: ellipsis
// display: -webkit-box; 必须结合的属性 ，将对象作为弹性伸缩盒子模型显示 。
// -webkit-box-orient 必须结合的属性 ，设置或检索伸缩盒对象的子元素的排列方式 。
// text-overflow，可以用来多行文本的情况下，用省略号“...”隐藏超出范围的文本 。
%word-break-2
  overflow: hidden
  text-overflow: ellipsis
  display: -webkit-box
  -webkit-line-clamp: 2
  -webkit-box-orient: vertical
  white-space: normal
%word-break-3
  overflow: hidden
  text-overflow: ellipsis
  display: -webkit-box
  -webkit-line-clamp: 3
  -webkit-box-orient: vertical
  white-space: normal
%menu-animation
  transition: all .3s ease-in-out
%img-ratio
  overflow: hidden
  width: 100%
  height: 0px
  position: relative
  img
    width: 100%
    height: 100%
    object-fit: cover
    position: absolute
    left: 0
    top: 0
%img-scale
  overflow: hidden
  position: relative
  .card-img
    border-radius: 0
    transition: .6s transform cubic-bezier(0.60, 0, 0.075, 1)

