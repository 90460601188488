
$gray-900:        #000;
$gray-800:        #161616;
$gray-700:        #231815;
$gray-600:        #3E3A39;
$gray-500:        #727272;
$gray-400:        #898989;
$gray-300:        #929292;
$gray-200:        #DCD6D2;
$gray-100:        #EFEFEF;

$yellow:          #E1B200;

$primary:         #A52F71;
$success:         #20A582;
$info:            #03b0c0;
$warning:         #FED400;
$danger:          #C5142A;
$secondary:       #DB611B;


$font:            #FFF;
$bg-color:        #181123;
$font-gray:       $gray-500;
$font-dark:       $gray-900;
$font-light:      $gray-400;

$border-radius:      0px;
$border-radius-lg:   5px;
$border-color:       $gray-100;