@import "reset"
@import "helpers/variables"
@import "helpers/extend"
@import "helpers/mixin"
@import "bootstrap"

[v-cloak]
  display: none

.fix-transform-bug
  -webkit-transform-style: preserve-3d

body
  position: relative
  background-color: $bg-color
  color: $font
  font-size: 14px
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  font-family: 'Noto Sans TC', 'Helvetica Neue', 'Helvetica', Roboto, 'Microsoft JhengHei', "Microsoft YaHei", "Apple Color Emoji", Arial, sans-serif
  &.modal-open
    padding-right: 0px !important
    .mm-slideout
      z-index: inherit !important

.container
  +xl-up
    padding-left: 75px
    padding-right: 75px

.serif
  font-family: 'Noto Serif TC', serif
.fw-600
  font-weight: 600
.fz-15
  +font-size(15px)
.fz-16
  +font-size(16px)
.fz-17
  +font-size(17px)
.fz-18
  +font-size(18px)
.fz-19
  +font-size(19px)
.fz-20
  +font-size(20px)
.fz-team
  +font-size(20px)
  color: $font-dark
  letter-spacing: 2.5px
  small
    +font-size(14px)
    color: $font-light
  &.border-bottom
    padding-bottom: 12px
.fz-title
  +font-size(19px)
  &.border-bottom
    padding-bottom: 12px
    // border-color: $gray-500 !important
a
  color: $font
  text-decoration: none
  &:hover,&:focus,&:active,&.active
    color: #FFF
    text-decoration: none
    outline: 0
  &.primary-link
    color: $primary
    text-decoration: underline
    &:hover,&:focus,&:active,&.active
      color: $primary
  &.dagner-link
    color: $danger
    text-decoration: underline
    &:hover,&:focus,&:active,&.active
      color: $danger

.form-control
  border: 0
  background: $gray-100
  &:focus
    box-shadow: none
    background: $gray-100
  &::placeholder
    color: $gray-500
    opacity: 1
  &:-ms-input-placeholder
    color: $gray-500
  &::-ms-input-placeholder
    color: $gray-500
.form-text
  color: $font-light

.opacity-50
  opacity: 0.5
.opacity-80
  opacity: 0.8
.btn
  display: inline-flex
  align-items: center
  justify-content: center
  transition: .2s all ease-out
  border-radius: 0
  &:focus
    outline: 0
    box-shadow: none
  &.more-wider
    padding-left: 1.5rem
    padding-right: 1.5rem
    min-width: 160px
  &.btn-outline-white
    border-color: #FFFFFF
    color: $primary
  .arr-right
    display: inline-block
    width: 8px
    margin-left: .5rem
    svg
      width: 100%
      float: left
  &.btn-success
    color: #FFF
  &.max-width-210
    max-width: 210px
    width: 100%
    &:hover
      letter-spacing: 2px
  &.w-100
    &:hover
      letter-spacing: 2px
  &.btn-primary
    color: #fff
  &.btn-danger
    color: #fff
    &:hover,&:focus
      color: #fff
  &.btn-line
    color: #fff
    background-color: #01C101
    &:hover,&:focus
      color: #fff
      background-color: darken(#01C101, 10%)
  &.btn-secondary
    color: #FFF

@keyframes animatedBackground
  0%
    background-position: 0 0
  100%
    background-position: 300% 0

.sticky-wrapper
  // height: auto !important
  &.is-sticky
    .page-header
      .navbar-brand
        .header-logo
          +md-up
            width: 152px
.page-header
  background: #FFFFFF
  padding-top: 12px
  padding-bottom: 12px
  position: fixed
  width: 100%
  min-width: 100vw
  z-index: 1020 !important
  +sm-down
    height: 65px
    padding-top: 7px
    padding-bottom: 7px
  .dropdown
    .dropdown-menu
      border: 0
      box-shadow: 0 0 15px rgba(0,0,0,.3)
      padding-top: 20px
      padding-bottom: 20px
      margin-top: -1px
      &.show
        top: 100%
      .dropdown-item
        position: relative
        padding: 0.4rem 2rem 0.4rem 3rem
        +font-size(15px)
        &:after
          content: ''
          display: block
          width: 2.5rem
          height: 2px
          background: $primary
          position: absolute
          top: 50%
          left: 0
          display: none
        &:hover,&:focus
          background: transparent
          color: $primary
          &:after
            display: block
        &.active
          background: transparent
          color: $primary
          font-weight: 600
          &:hover
            background: transparent
            color: $primary
            &:after
              display: block
          &:after
            display: block

  .navbar
    justify-content: initial
    --bs-navbar-padding-y: .4rem
    +sm-down
      display: none
    .navbar-collapse
      justify-content: flex-end
  .navbar-nav
    font-weight: 200
    font-size: 0.9rem
    flex-direction: row
    .nav-item
      position: relative
      font-weight: 400
      padding: 0
      display: flex
      align-items: center
      &:not(:first-child)
        &::before
          content: ''
          display: block
          width: 1px
          height: 40%
          position: absolute
          top: 30%
          left: 0
          background: #000
      .btn
        padding: .5rem 2rem
        font-size: 0.9rem
        +sm-down
          padding: .5rem 1.5rem
          font-size: 0.875rem
    .nav-link
      color: #000000
      position: relative
      z-index: 10
      padding: .5rem 1.2rem
      display: flex
      align-items: center
      font-size: .95rem
      font-weight: 400
      +md-down
        padding: .75rem 1rem
      &:hover, &:focus, &:active, &.active
        color: $primary
        &::before
          width: calc(100% - 2rem)
      .text
        z-index: 30
        position: relative
      .icon
        width: 17px
        display: inline-block
        margin-right: .3rem
        path
          fill: #ffffff
      &::before
        @extend %menu-animation
        animation-delay: .2s
        content: ''
        display: block
        position: absolute
        height: 2px
        width: 0%
        left: 50%
        bottom: -2px
        transform: translateX(-50%)
        max-width: auto
        background-color: $primary
        z-index: 0
  .navbar-brand
    padding: 0
    color: #FFF
    +md-down
      margin: 0 auto
    .header-logo
      width: 360px
      transition: .3s width ease-out
      line-height: 0
      +xl-down
        width: 220px
      +sm-down
        width: 200px
      > a
        display: flex
        align-items: center
        .logo-icon
          width: 24%
          flex: 0 0 24%
          margin-right: 8px
        .logo-text
          flex-grow: 1
          padding-top: .5rem
  .nav-secrch
    .search-group
      position: relative
      min-width: 200px
      +md-down
      +xl-down
        min-width: 100px
      .btn
        position: absolute
        top: 0
        right: 0
        width: 40px
        height: 100%
        img
          width: 16px
      input
        background: transparent
        padding-left: 0
        padding-right: 40px
        border: 0
        border-bottom: 1px solid $dark
        &:focus
          box-shadow: none

.row
  &.gutters-xl
    margin-left: -55px
    margin-right: -55px
    +md-down
      margin-left: -15px
      margin-right: -15px
    +sm-down
      margin-left: -10px
      margin-right: -10px
    *[class^='col']
      padding-left: 55px
      padding-right: 55px
      +md-down
        padding-left: 15px
        padding-right: 15px
      +sm-down
        padding-left: 10px
        padding-right: 10px
  &.gutters-lg
    margin-left: -35px
    margin-right: -35px
    +md-down
      margin-left: -15px
      margin-right: -15px
    +sm-down
      margin-left: -10px
      margin-right: -10px
    *[class^='col']
      padding-left: 35px
      padding-right: 35px
      +md-down
        padding-left: 15px
        padding-right: 15px
      +sm-down
        padding-left: 10px
        padding-right: 10px
.mm-menu
  // background: $gray-700
  &.mm-theme-dark
    background: darken($primary, 8%)
    .mm-listview
      .nav-item
        &.active,&:hover,&:focus
          color: #FFF
          background: darken($primary, 5%)
          .nav-link, .mm-next
            color: $white
        .nav-link, .mm-next
          color: $white
      a
        color: $white
        font-size: 20px
        font-weight: 300
        padding: 16px 24px
        &.active,&:hover,&:focus
          color: #FFF
.slick-slider
  .slick-track
    transform: none
  .slick-list
    transform: none
.slick-slide
  img
    width: 100%
.slick-arrow
  position: absolute
  top: calc(50% - 23px)
  text-indent: 999px
  overflow: hidden
  background: transparent
  width: 46px
  height: 46px
  margin: 0
  border: 0
  z-index: 561
  border-radius: 50%
  &::after
    content: ''
    display: block
    border-left: 2px solid $font-gray
    border-bottom: 2px solid $font-gray
    transform: rotate(45deg)
    width: 20px
    height: 20px
    position: absolute
    top: 14px
    left: 18px
  &:hover
    outline: 0
    &::after
      border-color: $gray-900
  &:focus
    outline: 0
  &.slick-disabled
    opacity: .3
    cursor: not-allowed
    &:after
      border-color: $gray-900
  &.slick-prev
    left: 15px
  &.slick-next
    right: 15px
    &::after
      right: 18px
      left: auto
      transform: rotate(225deg)
.slick-dots
  margin: 0
  padding: 0
  text-align: center
  position: absolute
  bottom: 0px
  left: 0
  width: 100%
  > li
    display: inline-block
    line-height: 0
    &.slick-active
      > button
        background: $gray-900
        border-color: $gray-900
    > button
      width: 10px
      height: 10px
      background: transparent
      border: 1px solid $gray-500
      border-radius: 50%
      padding: 0
      &:hover, &:focus
        margin: 0 3px
      margin: 0 3px
      text-indent: -9999px
      overflow: hidden
      img
        transition: width .2s cubic-bezier(0, 0, 0.44, 1.18)
      &:hover,&:focus,&:active,&.active
        outline: 0 !important


//default state
.menutoggler
  border: 0
  width: 50px
  height: 50px
  display: flex
  align-items: center
  justify-content: center
  position: absolute
  top: 7px
  left: 15px
  z-index: 1099
  border-radius: 5px
  display: none
  +md-down
    display: flex
  &:hover, &:focus
    outline: 0
    .menu-icon
      &_bar
        background-color: $primary
$mu-icon-size:  24px
.menu-icon
  // height: $mu-icon-size
  width: 24px
  // position: absolute
  z-index: 2
  cursor: pointer
  &_bar
    height: 2px
    width: 24px
    display: block
    background-color: $black
    margin-bottom: 6px
    transition: transform .2s ease, background-color .5s ease
  &_bar-first
    // width: $mu-icon-size / 2
  &_bar-last
    margin-bottom: 0
    // width: $mu-icon-size / 2
    float: right

.mm-opened
  .mm-page
    overflow: hidden
  // #LaunchPage
  //   position: relative
  .menu-icon
    &_bar
      transform: translateX(0px) rotate(-45deg)
    &_bar-first
      transform: translate(1px, 3px) rotate(45deg)
      width: calc($mu-icon-size / 2)
    &_bar-last
      transform: translate(-1px, -3px) rotate(45deg)
      width: calc($mu-icon-size / 2)
      float: right
section
  +sm-down
    overflow-x: hidden
  &.offset-y
    padding: 100px 0
    +md-down
      padding: 60px 0
  .border-title
    &::after
      content: ''
      display: block
      width: 90px
      height: 2px
      margin: 24px auto
      background: #FFF
      +sm-down
        width: 50px
        margin: 20px auto 12px
  ul, ol
    list-style-position: outside
  ol
    list-style: decimal
    padding-left: 20px
    &.lower-latin
      list-style: lower-latin
    &.upper-latin
      list-style: upper-latin
  .hgroup
    margin-bottom: 1rem
    max-width: 720px
    margin: 20px auto 80px
    +md-down
      margin: 12px auto 40px
    .logo
      width: 120px
      margin: 0 auto 20px
      +md-down
        width: 80px
    h2
      font-size: 62px
      font-weight: 100
      text-transform: uppercase
      letter-spacing: 2px
      margin-bottom: 36px
      +md-down
        font-size: 42px
        margin-bottom: 24px
      +sm-down
        font-size: 28px
    p
      font-size: 16px
      font-weight: 300
      margin-bottom: 8px
      +sm-down
        font-size: 14px
  .section-container
    padding-left: 20px
    padding-right: 20px
    +md-up
      padding-left: 80px
      padding-right: 80px
    +lg-up
      padding-left: 100px
      padding-right: 100px

.about-section
  .row
    --bs-gutter-x: 2px
    --bs-gutter-y: 2px
.page-footer
  margin-top: 80px
  +md-down
    margin-top: 60px
  .footer-below
    background-color: rgba(0,0,0,.2)
    font-size: 13px
    > .container
      padding-top: 30px
      padding-bottom: 30px
      position: relative
      &::before
        content: ""
        display: block
        width: 100%
        height: 1px
        background: rgba(255,255,255,.5)
        position: absolute
        left: 0
        top: -1px
        +xl-up
          width: calc(100% - 150px)
          left: 75px
      +sm-down
        padding-top: 15px
        padding-bottom: 15px
    a
      color: $font-light
      &:hover,&:focus,&.active,&:active
        color: $white
  .copyright-wrapper
    display: flex
    justify-items: flex-end
  .footer-meta
    +md-up
      display: flex
      grid-gap: 24px
      align-items: center
    .footer-logo
      width: 100px
      +md-down
        margin: auto
      img
        width: 100%
    ul
      padding: 0
      margin: 0
  .copyright, .company
    margin-bottom: 0
    +md-down
      text-align: center
    a
      display: inline-block
      padding: 2px 10px
  .social-icon
    display: flex
    align-items: center
    grid-gap: 15px
    +sm-down
      margin-top: 16px
    .social-link
      display: flex
      align-items: center
      color: #FFF
    .icon
      &.wechat
        svg
          width: 26px
          height: 26px
      &.youtube
        svg
          width: 22px
          height: 22px
      svg
        width: 20px
        height: 20px
        path, polyline
          stroke: #FFF

.pagination
  display: flex
  align-items: center
  justify-content: center
  margin-top: 2rem
  margin-bottom: 2rem
  .page-item
    &:hover, &:focus, &:active, &.active
      .page-link
        background-color: $primary
        color: $white
        border-color: $primary
    &.disabled
      .page-link
        background-color: rgba($gray-200,0.2)
        color: $gray-500
        border-color: $gray-200
        opacity: .5
    .page-link
      border-radius: 50%
      width: 36px
      height: 36px
      margin: 0 5px
      display: flex
      align-items: center
      justify-content: center
      padding: 0
      text-align: center
      background-color: transparent
      color: $font
      +font-size(16px)
      background: #000
      border: 1px solid $gray-500
      +sm-down
        +font-size(14px)
      &.previous, &.next
        +md-up
          width: auto
          padding: 0 12px
          border-radius: 50px
      &.previous
        +md-up
          padding-right: 20px
      &.next
        +md-up
          padding-left: 20px
      svg
        width: 20px

.accordion
  --bs-accordion-border-color: #727272
  --bs-accordion-btn-focus-box-shadow: none
  --bs-accordion-color: #727272
  --bs-accordion-active-bg: transparent
  color: $font-gray
.accordion-item
  border-bottom: $font-gray 1px solid !important
  +lg-up
    padding-right: 35%
  &:first-of-type
    border-top: $font-gray 1px solid !important
  .accordion-button
    color: $font-light
    +sm-down
      padding-left: 0
      padding-right: 0
    &:not(.collapsed)
      color: $font-light
      box-shadow: none
  .accordion-body
    padding-top: 0
    padding-left: 50px
    padding-right: 80px
    +sm-down
      padding-left: 30px
      padding-right: 40px


.static-content
  color: $font-light
  +font-size(15px)
  line-height: 1.85
  p, li
    margin-bottom: 0.5rem
  ul
    padding-left: 0
    &.first
      > li
        margin-bottom: 25px
    &.style-disc
      > li
        list-style: disc
  li
    list-style-position: inside
    > ul, ol
      padding-left: 20px
    > ol
      list-style: cjk-ideographic
  img
    max-width: 100%
  h1,h2,h3,h4,h5,h6
    font-weight: 600
    color: $font
    margin-bottom: 15px
  h1
    +font-size(32px)
  h2
    +font-size(24px)
  h3
    +font-size(20px)
  h4
    +font-size(18px)
  h5
    +font-size(16px)
  h6
    +font-size(14px)

.card-culture
  h3
    font-size: 18px
    margin: 12px 0 0
    +md-down
      font-size: 16px
    +sm-down
      font-size: 14px
#goTop
  width: 42px
  height: 42px
  border-radius: 50%
  background: $secondary
  position: fixed
  bottom: 30px
  right: 30px
  z-index: 982
  display: none
  align-items: center
  justify-content: center
  cursor: pointer
  &.show
    display: flex
  svg
    width: 18px
    height: 18px
    stroke: #fff

.joinus-section
  position: relative
  .hgroup
    position: absolute
    bottom: 0
    left: 0
    padding-left: 15px
    padding-right: 15px
    +md-up
      top: 40%
      left: 50%
      padding-left: 0
      padding-right: 0
      transform: translate(-50%, -50%)
  .global-map
    padding: 80px 0
    opacity: .45
    +sm-down
      padding-top: 0

.kv-banner
  .slick-slide
    img
      +md-down
        width: 100%
// side  abar style hear
body
  &.sideMenu-show
    overflow: hidden
    #LaunchPage
      transition: transform 600ms
      transform: translate3d(80%,0,0)
    .menu-icon
      &_bar
        transform: translateX(0px) rotate(-45deg)
      &_bar-first
        transform: translate(1px, 3px) rotate(45deg)
        width: calc($mu-icon-size / 2)
      &_bar-last
        transform: translate(-1px, -3px) rotate(45deg)
        width: calc($mu-icon-size / 2)
        float: right

.side-bar
  position: fixed
  overflow: hidden
  top: 0
  left: -100%
  width: 80%
  max-width: 400px
  height: 100%
  display: block
  backdrop-filter: blur(7px)
  z-index: 1900
  transition: all 600ms ease
  display: flex
  flex-direction: column
  overflow-y: auto
  overflow: visible
  background-color: #85265b
  .inner
      padding: 0
  &.show
    left: 0
    overflow-y: auto
  button
    max-width: 100%
    height: 40px
    width: 40px
    position: absolute
    left: 0
    top: 0
    padding: 6px
    i
      color: #fff
      border-radius: 0
      display: flex
      align-items: center
      justify-content: center
      font-size: 20px


#anywhere-home
  cursor: url(../images/icon/close.png), auto
  background: #0e1013
  position: fixed
  width: 100%
  height: 100%
  opacity: 0
  visibility: hidden
  transition: opacity 500ms ease-in-out
  pointer-events: none
  z-index: 50
  &.bgshow
    background: #0e1013
    opacity: 70%
    visibility: visible
    pointer-events: visible
    z-index: 999
    top: 0
.mobile-menu
  nav
    ul
      padding: 0
      li
        margin: 0
        padding: 0
        a.nav-link
          font-size: 1.25rem
          padding: 20px 30px
          border-bottom: 1px solid rgba(255, 255, 255, 0.04)
          cursor: pointer
          position: relative
          color: #fff
          &:hover
            background: $primary
          &::after
            position: absolute
            content: ''
            font-size: 16px
            right: 0
            font-weight: 400
            top: 50%
            transform: translateY(-50%)
            height: 35px
            width: 35px
            line-height: 35px
            display: block
            text-align: center
            color: #fff
            pointer-events: none
            cursor: pointer
            border-radius: 3px
